import { PAFSubmissionForm } from "components/CarespacePage/PAFSubmission/PAFSubmissionForm";
import { z } from "zod";
import { PAFSchema, usePAFSubmissionForm } from "./pafFormUtil";


interface PAFSubmissionPageProps {
  isReadOnly: boolean;
}

export const PAFSubmissionPage: React.FC<PAFSubmissionPageProps> = ({ isReadOnly }) => {
  const form = usePAFSubmissionForm({} as z.infer<typeof PAFSchema>)
  return (
    <div className="flex flex-col gap-2">
      <h1>PAF Submission</h1>
      <PAFSubmissionForm form={form} isReadOnly={isReadOnly} />
    </div>
  );
}
