import { UserAdlo } from "backend/resources/userAdlo";
import { AdloInitials } from "components/AdloComponent/AdloInitials";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { useActiveNetworkId } from "state/network/network";

interface CarespaceInfoBannerProps {
  userAdlo: UserAdlo;
}

export const CarespaceInfoBanner = ({
  userAdlo,
}: CarespaceInfoBannerProps) => {
  const navigate = useAppNavigate();
  const networkId = useActiveNetworkId();
  return (
    <div className={`text-md grid grid-cols-12 w-full mt-6 border border-gray-200 p-4`}>
        <div className="col-span-1 flex items-center justify-center h-full">
          <AdloInitials
            userAdlo={userAdlo}
            size={"default"}
          />
        </div>
        <div className="col-span-3 flex flex-col">
          <p className="text-lg">{userAdlo?.first_name} {userAdlo?.last_name}</p>
          {userAdlo?.birthday && <p>DOB: {new Date(userAdlo?.birthday).toLocaleDateString('en-US', { timeZone: 'UTC' })}</p>}
          {userAdlo?.sex && <p>Sex: {userAdlo?.sex}</p>}
          {userAdlo?.dyad && <p>Dyad: {userAdlo?.dyad}</p>}
        </div>
        <div className="col-span-4 flex flex-col">
          {/* TODO: Add MN Number */}
          {/* <p>MN: 12345</p> */}
          {networkId &&
            <p className="flex flex-row items-center">
              <span className="mr-2">Guide Alignment: </span>
              <ButtonWithIcon
                size="small"
                className="mr-2"
                onClick={() => 
                  navigate({
                    path: CareCentralRoute.PAF_SUBMISSION_VIEW,
                    params: { id: networkId }
                  })
                }
                icon={IconOption.ARROW}
                text="View"
              />
              <ButtonWithIcon
                size="small"
                className="mr-2"
                onClick={() => navigate({
                  path: CareCentralRoute.PAF_SUBMISSION_EDIT,
                  params: { id: networkId ?? "" }
                })}
                icon={IconOption.ARROW}
                text="New / Edit"
              />
            </p>
          }
          {/* TODO: Add Guide Alignment Checkbox */}
          {/* <p className="flex flex-row items-center">
            <DefaultCheckbox 
              checked={isGuideAligned}
              disabled
              label="Aligned"
            />
          </p> */}
        </div>
        {/* TODO: Add rest of Carespace Info*/}
    </div>
  )
}