import { CraniometrixProduct } from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { ReactNode } from "react";
import ModalView from "shared/ui/modal";
import { Popup } from "shared/ui/popup";

type Props = {
  isOpen: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  isNetworkCallInProgress?: boolean;
  closeText?: string;
  title?: ReactNode;
  description?: ReactNode;
  showLogo?: CraniometrixProduct | "base";
  isLogoCentered?: boolean;
  logoSize?: "small" | "large";
  footerButtons?: ReactNode;
  children?: ReactNode;
  fixedHeight?: string;
  customWidth?: string;
  customMaxWidth?: string;
};

export function ResponsiveModal(props: Props) {
  const {
    onClose,
    closeText,
    title,
    showLogo,
    isLogoCentered,
    logoSize = "small",
    footerButtons,
    children,
  } = props

  const { isMobile } = useWindowSize()

  if (isMobile) {
    return (
      <ModalView
        isOpen={props.isOpen}
        onClose={onClose}
        closeText={closeText}
        title={title}
        showLogo={showLogo}
        isLogoCentered={isLogoCentered}
        logoSize={logoSize}
        footerButtons={footerButtons}
      >
        {children}
      </ModalView>
    );
  } else {
    console.log(props.customWidth)
    return (
      <Popup {...props} />
    )
  }
}