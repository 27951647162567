import { DatePicker } from "@mui/x-date-pickers";
import { DefaultCheckbox } from "components/Checkbox";
import { Select } from "components/Select/Select";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { z } from "zod";
import { PhoneType, Race, Relationship, Sex } from "./pafEnums";
import { getCurrentValueForSelect, getSelectOptionsFromEnum, PAFSchema } from "./pafFormUtil";


interface PrimaryCareGiverInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const PrimaryCareGiverInputs: React.FC<PrimaryCareGiverInputsProps<z.infer<typeof PAFSchema>>> = ({ form, isReadOnly }) => {
  const formValues = form.watch();

  return (
    <>
      {/* PCG First Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG First Name"
        fieldName="pcg_first_name"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG Last Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Last Name"
        fieldName="pcg_last_name"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG Address */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Address"
        fieldName="pcg_address_line"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG City */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG City"
        fieldName="pcg_address_city"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG State */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG State"
        fieldName="pcg_address_state"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG Zip Code */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Zip Code"
        fieldName="pcg_address_postalCode"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG Email */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Email"
        fieldName="pcg_email"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG Phone */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Phone"
        fieldName="pcg_phone"
        children={(field) => (
          <Input {...field} />
        )}
      />
      {/* PCG Phone Type */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Phone Type"
        fieldName="pcg_phone_type"
        children={(field) => (
          <Select 
            options={getSelectOptionsFromEnum(PhoneType)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Date of Birth */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Date of Birth"
        fieldName="pcg_date_of_birth"
        children={(field) => (
          <DatePicker {...field} />
        )}
      />
      {/* PCG Sex */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Sex"
        fieldName="pcg_sex"
        children={(field) => (
          <Select 
            options={getSelectOptionsFromEnum(Sex)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Race */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Race"
        fieldName="pcg_race"
        children={(field) => (
          <Select 
            options={getSelectOptionsFromEnum(Race)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Relationship */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Relationship"
        fieldName="pcg_relationship"
        children={(field) => (
          <Select 
            options={getSelectOptionsFromEnum(Relationship)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PCG Lives with patient */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Lives with Patient"
        fieldName="pcg_residence"
        children={(field) => (
          <DefaultCheckbox {...field} />
        )}
      />
      {/* PCG Is Medicare Beneficiary */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Is Medicare Beneficiary"
        fieldName="pcg_medicare_status"
        children={(field) => (
          <DefaultCheckbox {...field} />
        )}
      />
      {/* PCG Medicare Beneficiary Identifier */}
      {formValues.pcg_medicare_status && (
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="PCG Medicare Beneficiary Identifier"
          fieldName="pcg_mbi"
          children={(field) => (
            <Input {...field} />
          )}
        />
      )}
      {/* PCG Zarit Burden Interview Score */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Zarit Burden Interview Score"
        fieldName="zbi_score"
        children={(field) => (
          <Input type={"number"} {...field} />
        )}
      />
      {/* PCG Years in Role */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Years in Role (if unknown leave blank)"
        fieldName="pcg_role_years"
        children={(field) => (
          <Input type={"number"} {...field} />
        )}
      />
      {/* PCG Months in Role */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCG Months in Role (if unknown leave blank)"
        fieldName="pcg_role_months"
        children={(field) => (
          <Input type={"number"} {...field} />
        )}
      />
    </>
  )
}