import { zodResolver } from "@hookform/resolvers/zod";
import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { UseFormReturn, useForm } from "react-hook-form";
import * as z from "zod";
import { legalSexOptions } from "components/CarePilotOnboarding/components/BasicInformationModal";
import { RadioButtonsGroup } from "components/RadioGroup";
import { useWindowSize } from "hooks/useWindowSize";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRoot,
  FormSection,
  FormSectionHeader,
} from "shared/ui/form";
import { Input } from "shared/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "shared/ui/select";
import { MultiPageFormProps } from "shared/forms/types";


export enum DyadTier {
  Low = "Low",
  Moderate = "Moderate",
  High = "High",
  NA = "N/A",
}

/**
 * schema
 */
export const carespaceInformationFormSchema = z.object({
  // carespace props
  carespaceName: z.string().min(2, {
    message: "Carespace name must be at least 2 characters.",
  }),
  // patient information
  patientFirstName: z.string(),
  patientLastName: z.string(),
  patientBirthday: z.date(),
  patientLegalSex: z.string(),
  dyad: z.enum(Object.values(DyadTier) as [DyadTier, ...DyadTier[]]),  // hasCaregiver can be true,false, null and until the user responds, undefined
  hasCaregiver: z.boolean().nullable(),
});

/**
 * form hook
 */
export const useCarespaceInformationForm = (defaultValues?: z.infer<typeof carespaceInformationFormSchema>) => useForm<z.infer<typeof carespaceInformationFormSchema>>({
  resolver: zodResolver(carespaceInformationFormSchema),
  defaultValues: defaultValues ?? {},
  mode: "onSubmit",
});

export type CarespaceInformationFormHook = UseFormReturn<z.infer<typeof carespaceInformationFormSchema>>

/**
 * form component
 */
export const carespaceInformationFormId = "carespace-information-form";

export function CarespaceInformationForm(
  props: MultiPageFormProps<z.infer<typeof carespaceInformationFormSchema>> & { isEditing?: boolean },
) {
  const { form, isEditing } = props
  const { isMobile } = useWindowSize()
  const formFields = form.watch();


  function handlePatientNameChange(fieldName: keyof z.infer<typeof carespaceInformationFormSchema>, fieldValue: string) {
    form.setValue(fieldName, fieldValue);

    const firstName = fieldName === "patientFirstName" ? fieldValue : formFields.patientFirstName ?? "";
    const lastName = fieldName === "patientLastName" ? fieldValue : formFields.patientLastName ?? "";

    form.setValue("carespaceName", `${firstName} ${lastName}`);
  }


  return (
    <Form {...form}>
      <FormRoot
        id={carespaceInformationFormId}
      >
        <FormSectionHeader title={isEditing ? "Edit carespace name and patient info" : "Step 1: Add carespace name and patient info"} />
        {/* patient information */}
        <p className="-mt-2">Patient information</p>
        <FormSection>
          <div className="grid grid-cols-[2fr,3fr] gap-2 -mt-2">
            <FormField
              control={form.control}
              name="patientFirstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First</FormLabel>
                  <FormControl>
                    <Input {...field} onChange={(e) => handlePatientNameChange(field.name, e.target.value)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="patientLastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last</FormLabel>
                  <FormControl>
                    <Input {...field} onChange={(e) => handlePatientNameChange(field.name, e.target.value)} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-[2fr,3fr] gap-2">
            <FormField
              control={form.control}
              name="patientBirthday"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>DOB</FormLabel>
                  <FormControl>
                    <DatePicker value={dayjs(field.value)} onChange={date => field.onChange(date?.toDate())}
                      disableOpenPicker={!isMobile}
                      slotProps={{
                        field: {
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                            }
                          },
                        },
                        textField: {
                          sx: {
                            fieldset: {
                              borderRadius: 2, borderColor: '#E4E4E7',
                            },
                            [`.${outlinedInputClasses.root}`]: {
                              height: 40,
                              width: 165,
                              fontSize: 14,
                              fontWeight: 400,
                              fontFamily: 'Poppins',
                            },
                          },
                        },
                      }} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="patientLegalSex"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Legal sex</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Object.values(legalSexOptions).map((option) => (
                        <SelectItem value={option.value}>
                          {option.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {/* carespace information */}
          <FormSection>
            <p className="mt-2">Carespace information</p>
            <FormField
              control={form.control}
              name="carespaceName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Carespace Name</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-4 mt-2">
              <p className="text-sm text-severe-red">Note: Changing the Tier or caregiver status will effect the scheduling of certain tasks per the GUIDE requirements.</p>
              <FormField
                control={form.control}
                name="dyad"
                render={({ field }) => (
                  <FormItem className="w-full flex flex-col ">
                    <FormLabel>DYAD Tier (if known)</FormLabel>
                    <RadioButtonsGroup
                      id={"dyad"}
                      options={Object.values(DyadTier).map((value) => ({ value, label: value }))} {...field}
                      value={field.value === null ? "N/A" : field.value}
                      row>
                    </RadioButtonsGroup>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="hasCaregiver"
                render={({ field }) => (
                  <FormItem className="w-full flex flex-col">
                    <FormLabel>Has a Caregiver (if known)</FormLabel>
                    <RadioButtonsGroup<boolean | string>
                      id={"hasCaregiver"}
                      options={[{ value: false, label: "No", }, { value: true, label: "Yes", }, { value: "N/A", label: "N/A", }]}
                      onChange={value => field.onChange(value === "N/A" ? null : value === "true")}
                      value={field.value === null ? "N/A" : field.value}
                      row>
                    </RadioButtonsGroup>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </FormSection>
        </FormSection>
      </FormRoot>
    </Form>
  );
}