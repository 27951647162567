export enum AssessmentType {
  INITIAL_ASSESSMENT = "initial assessment",
  REASSESSMENT = "re-assessment"
}

export enum InitialPatient {
  EXISTING_PATIENT = "existing patient",
  NEW_PATIENT = "new patient"
}

export enum PTReferralSource {
  HEALTHCARE_PROVIDER = "healthcare provider",
  COMMUNITY_BASED_ORGANIZATION = "community based organization",
  SELF_REFERRAL = "self-referral"
}

export enum ReassessmentReason {
  ANNUAL_REASSESSMENT = "annual re-assessment",
  REASSESSMENT_DUE_TO_CHANGE_IN_SEVERITY = "re-assessment due to change in severity of patient's dementia",
  REASSESSMENT_DUE_TO_CHANGE_IN_CAREGIVER_STATUS = "re-assessment due to change in caregiver status"
}

export enum CaregiverStatus {
  NEW_PRIMARY_CAREGIVER = "new primary caregiver",
  LOSS_OF_CAREGIVER = "loss of caregiver",
  PATIENT_CHANGE_IN_RESIDENCE = "patient change in residence",
  OTHER = "other"
}

export enum ResidenceType {
  PRIVATE_RESIDENCE = "private residence",
  ASSISTED_LIVING_FACILITY = "assisted living facility",
  MEMORY_CARE_PROGRAM = "memory care program"
}

export enum PhoneType {
  HOME = "home",
  BUSINESS = "business",
  MOBILE = "mobile"
}

export enum DementiaStageAssessmentTool {
  FAST = "FAST",
  CDR = "CDR",
  ALTERNATIVE = "ALTERNATIVE"
}

export enum HasPrimaryCareGiver {
  YES_MULTIPLE = "Yes (multiple)",
  YES_ONE = "Yes (one)",
  NO = "No",
  UNDETERMINED = "Undetermined"
}

export enum Sex {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown"
}

export enum Race {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "American Indian or Alaska Native",
  ASIAN = "Asian",
  BLACK_OR_AFRICAN_AMERICAN = "Black or African American",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = "Native Hawaiian or Other Pacific Islander",
  WHITE = "White",
  ASKED_BUT_UNKNOWN = "Asked but unknown",
  OTHER = "Other",
  UNKNOWN = "Unknown"
}

export enum Relationship {
  SPOUSE = "Spouse",
  DOMESTIC_PARTNER = "Domestic partner",
  DAUGHTER = "Daughter",
  SON = "Son",
  SIBLING = "Sibling",
  OTHER_FAMILY_MEMBER = "Other family member",
  FRIEND = "Friend",
  OTHER_NON_FAMILY_MEMBER = "Other non-family member"
}

export enum ClinicianAttestation {
  YES_DIAGNOSIS = "Yes, the patient meets the National Institute on Aging-Alzheimer\’s Association diagnostic guidelines for dementia and/or the DSM-5 diagnostic guidelines for major neurocognitive disorder",
  YES_REPORT = "Yes, I received a written report of a documented dementia diagnosis",
  NO = "No, I cannot attest to either statement"
}