import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { z } from "zod";
import { PAFSchema } from "./pafFormUtil";


interface PrimaryCarePhysicianInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const PrimaryCarePhysicianInputs: React.FC<PrimaryCarePhysicianInputsProps<z.infer<typeof PAFSchema>>> = ({ form, isReadOnly }) => {
  return (
    <>
      {/* PCP First Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCP First Name"
        fieldName="pcp_first_name"
        children={(field) => (
          <Input type={"text"} {...field} />
        )}
      />
      {/* PCP Last Name */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCP Last Name"
        fieldName="pcp_last_name"
        children={(field) => (
          <Input type={"text"} {...field} />
        )}
      />
      {/* PCP Phone */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PCP Phone"
        fieldName="pcp_phone"
        children={(field) => (
          <Input type={"text"} {...field} />
        )}
      />
    </>
  )
}