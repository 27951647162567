import { useGenerateCarePlan } from "backend/resources/carePlan/carePlan";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";
import { useUserStore } from "state/user";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}
export default function GenerateCarePlanSubmissionPopup({ isOpen, onClose }: Props) {
  const navigate = useAppNavigate()
  const authUser = useUserStore((state) => state.user);
  // Mutations 
  const generateCarePlan = useGenerateCarePlan().mutateAsync

  return (
    <PopupOrSheet
      isOpen={isOpen}
      onClose={onClose}
      footerButtons={
        <>
          <ButtonWithIcon
            className="text-xs md:text-base"
            onClick={onClose}
            size={"small"}
            text={"Cancel"}
            icon={IconOption.CANCEL}
          />

          <ButtonWithIcon
            className="text-xs md:text-base"
            onClick={async () => {
              await generateCarePlan()
              navigate({ path: CareCentralRoute.CARESPACE, params: { carespaceId: authUser?.id ?? '' }, queryParams: { tab: CarespacePageTabs.CARE_PLAN } })
              onClose();
            }}
            size={"small"}
            text={"Continue"}
            icon={IconOption.ARROW}
          />

        </>
      }
    >
      <p>Are you sure you want to generate a care plan?</p>

    </PopupOrSheet>
  )
}
