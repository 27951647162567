import { useNetworksInOrganization } from "backend/resources/network/network"
import BottomActionBar from "components/BottomActionBar/BottomActionBar"
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon"
import { CardLayoutTypes } from "components/CardLayoutSelector/CardLayoutSelector"
import { CarespaceCard } from "components/HomePage/CareCentralHome/Carespaces"
import { LoadingSpinner } from "components/LoadingSpinner"
import { CarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/CarespaceCreationWizard"
import { useCarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard"

interface CarespaceCardsProps {
  isCreatePopoverOpen: boolean;
  setIsCreatePopoverOpen: (isOpen: boolean) => void;
  activeLayout: CardLayoutTypes;
  setActiveLayout: (layout: CardLayoutTypes) => void;
}


export function CarespaceCards({ isCreatePopoverOpen, setIsCreatePopoverOpen, activeLayout, setActiveLayout }: CarespaceCardsProps) {
  const { networks, isLoading } = useNetworksInOrganization();
  const reset = useCarespaceCreationWizard((state) => state.reset)


  if (isLoading) {
    return <div className="flex justify-center items-center min-h-screen">
      <LoadingSpinner className="w-32 h-32" />
    </div>
  }

  return (
    <>
      {/* Carespaces */}
      <div className="flex flex-col w-full gap-3">
        {networks && networks.length > 0 ?
          <div
            className={`flex flex-wrap justify-center md:justify-start items-center md:items-start gap-1 md:gap-2 pt-2`}>
            {networks.map((network) => {
              return (
                <CarespaceCard
                  network={network}
                  activeLayout={activeLayout}
                />
              );
            })}
          </div>
          : <p className="mt-2 text-sm">
            You currently don’t have any carespaces. Click on "Create New" to add a carespace.
          </p>
        }
      </div>

      {/* Carespace Creation Popup */}
      {isCreatePopoverOpen &&
        <CarespaceCreationWizard
          onClose={() => {
            reset()
            setIsCreatePopoverOpen(false)
          }}
        />
      }

      {/* Bottom Action Bar */}
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              reset()
              setIsCreatePopoverOpen(true)
            }}
            text={""}
            icon={IconOption.PLUS}
          />
        }
      />
    </ >
  )
}