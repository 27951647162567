import { Select } from "components/Select/Select";
import { Input } from "shared/ui/input";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField } from "shared/ui/form";
import { z } from "zod";
import { CaregiverStatus, InitialPatient, ReassessmentReason } from "./pafEnums";
import { getCurrentValueForSelect, getSelectOptionsFromEnum, PAFSchema } from "./pafFormUtil";


interface InitialAssessmentInputsProps<T extends FieldValues>  {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const InitialAssessmentInputs: React.FC<InitialAssessmentInputsProps<z.infer<typeof PAFSchema>>> = ({ form, isReadOnly }) => {
  const formValues = form.watch();

  return (
    <>
      {/* Initial Patient */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="Initial Patient"
        fieldName="initial_patient"
        children={(field) => (
          <Select 
            options={getSelectOptionsFromEnum(InitialPatient)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* PT Referral Source */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="PT Referral Source"
        fieldName="pt_referral_source"
        children={(field) => (
          <Input {...field}/>
        )}
      />
      {/* Reassessment Reason */}
      <CustomFormField 
        form={form}
        disabled={isReadOnly}
        labelName="Reassessment Reason"
        fieldName="reassessment_reason"
        children={(field) => (
          <Select 
            options={getSelectOptionsFromEnum(ReassessmentReason)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />
      {/* Caregiver Status */}
      {formValues.reassessment_reason == ReassessmentReason.REASSESSMENT_DUE_TO_CHANGE_IN_CAREGIVER_STATUS && (
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Caregiver Status"
          fieldName="caregiver_status"
          children={(field) => (
            <Select 
              options={getSelectOptionsFromEnum(CaregiverStatus)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
      )}
      {/* Caregiver Status */}
      {formValues.caregiver_status == CaregiverStatus.OTHER && (
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Please specify"
          fieldName="caregiver_status_os"
          children={(field) => (
            <Input {...field}/>
          )}
        />
      )}
    </>
  )
}