import { AppLayout } from "components/App/AppLayout";
import { ReactElement } from "react";
import { Route } from "react-router-dom";
import { ProtectedRoute, RouteLayout, RoutLayoutObject } from "routes";

export function renderRoute(route: RoutLayoutObject) {
  const {
    routeLayout,
    element,
    path,
    hideSideNav,
  } = route;
  // Start with the base element  
  let finalElement = element;
  
  // Wrap with the parentElement if it's provided
  if (routeLayout === RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT) {
    finalElement = <ProtectedRoute component={<AppLayout>{finalElement}</AppLayout>} />;
  }
  if (routeLayout === RouteLayout.PROTECTED_ROUTE) {
    finalElement = <ProtectedRoute component={finalElement as ReactElement} />;
  }
  if (routeLayout === RouteLayout.APP_LAYOUT) {
    finalElement = <AppLayout hideSideNav={hideSideNav}>{finalElement}</AppLayout>;
  }

  return (
    <Route
      key={path}
      path={path}
      element={finalElement}
    />
  );
}
