import { CarePlanGoalStatuses } from "backend/resources/carePlan/carePlan";
import {
  useDeleteGoalMutation,
  useGoalQueryById,
  useSaveGoalMutation,
} from "backend/resources/goal/goal";
import { useUserRecommendationsForGoal } from "backend/resources/goal/goalUserRecommendationStatus";
import {
  useUserRecommendationsFromUserRecommendationIds,
  type RecommendationType,
} from "backend/resources/userRecommendation";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CloseButton } from "components/CloseButton/CloseButton";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { GoalTemplateAddPopover } from "components/GoalPage/GoalTemplate/GoalTemplateSelectPopover";
import { ShareableRecommendationAddPopover } from "components/GoalPage/GoalTemplate/ShareableRecommendationSelectPopover";
import { TimeFrame, Timeline } from "components/GoalPage/utils";
import { RecommendationCard } from "components/GuidancePage/Recommendations/Recommendation";
import ModalView from "components/ModalView/ModalView";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useCarePlanStore } from "state/carePlan/carePlan";
import { useGoalStore } from "state/goal/goal";
import { useUserStore } from "state/user";

export default function CreateOrEditGoalPage() {
  const { id } = useParams();
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  const { resetSession } = useGoalStore();
  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title={id ? "Edit Goal" : "Add Goal"}
        closeText="Back"
        onClose={() => {
          resetSession(id ?? "");
          navigateBack()
        }}>
        <PageContainer>
          <CreateOrEditGoalPageComponent />
        </PageContainer>
      </ModalView>
    );
  } else {
    return (
      <PageContainer>
        <ButtonWithIcon
          onClick={() => {
            navigateBack();
            resetSession(id ?? "");
          }}
          text="Back"
          size="small"
          className="w-full justify-center flex md:justify-start mb-4"
          icon={IconOption.BACK_ARROW}
        />
        <PageMainHeader text={id ? "Edit Goal" : "Add goal"} />
        <CreateOrEditGoalPageComponent />
      </PageContainer>
    );
  }
}

function CreateOrEditGoalPageComponent() {
  /// //////
  // Vars
  /// /////

  const navigate = useAppNavigate()
  const navigateBack = useNavigateBack()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const authUser = useUserStore((state) => state.user);
  const { id: goalId } = useParams();

  /// //////
  // Local State
  /// /////

  const [
    isAddRecommendationsPopupShowing,
    setIsAddRecommendationsPopupShowing,
  ] = useState(false);

  const [isAddFromTemplatesPopupShowing, setIsAddFromTemplatesPopupShowing] =
    useState(false);

  const [
    isAddRecommendationsFromTemplatePopupShowing,
    setIsAddRecommendationsFromTemplatePopupShowing,
  ] = useState(false);

  const [localShareableRecommendations, setLocalShareableRecommendations] =
    useState<RecommendationType[]>([]);

  const [hasInitialized, setHasInitialized] = useState(false)

  /// //////
  // Stores
  /// /////

  const {
    setSession,
    sessions,
    resetSession,
  } = useGoalStore();

  const defaultGoalId = goalId ?? ""

  const {
    title,
    details,
    timeframe,
    recommendationIds,
    activeTemplateId,
  } = sessions[defaultGoalId] ?? {};

  const { setCurrentCarePlanGoalsByAdlo: setCurrentCarePlanGoals, currentCarePlanGoalsByAdlo, setNewCustomGoals, newCustomGoals } = useCarePlanStore();
  /// //////
  // Hooks/Mutations
  /// /////

  const { data: userRecommendations } =
    useUserRecommendationsFromUserRecommendationIds(recommendationIds || []);

  const { data: existingRecommendations } =
    useUserRecommendationsForGoal(goalId);
  const { goal } = useGoalQueryById(goalId);
  const userAdloId = queryParams.get("user_adlo_id") || goal?.adlo_id;
  const currentCarePlanGoals = userAdloId ? currentCarePlanGoalsByAdlo[userAdloId] ?? [] : [];

  const saveGoal = useSaveGoalMutation(goalId).mutateAsync;
  const deleteGoalMutation = useDeleteGoalMutation().mutateAsync;
  // Handler

  async function onSave() {
    if (title && details && timeframe && userAdloId) {
      const savedGoal = await saveGoal({
        goal: {
          ...goal,
          title,
          goal_template_id: activeTemplateId,
          description: details,
          timeline: timeframe,
          adlo_id: userAdloId,
        },
        recommendationIds,
      });
      if (savedGoal && !goalId) {
        setNewCustomGoals([...newCustomGoals, savedGoal]);
        setCurrentCarePlanGoals(
          userAdloId,
          [...currentCarePlanGoals, { ...savedGoal, status: CarePlanGoalStatuses.INCLUDED, progress: 0, rating: null }]);
      }
    }
    resetSession(defaultGoalId);
  }

  useEffect(() => {
    if (!hasInitialized) {
      setSession(defaultGoalId, {
        ...sessions[defaultGoalId],
        title: sessions[defaultGoalId]?.title ?? goal?.title ?? "",
        details: sessions[defaultGoalId]?.details ?? goal?.description ?? "",
        timeframe: sessions[defaultGoalId]?.timeframe ?? (goal?.timeline as Timeline) ?? Timeline.SHORT_TERM,
        recommendationIds: sessions[defaultGoalId]?.recommendationIds?.length
          ? sessions[defaultGoalId].recommendationIds
          : existingRecommendations?.map((rec) => rec.id) ?? [],
      });
      setHasInitialized(true);
    }
  }, [existingRecommendations, goal, hasInitialized]);

  useEffect(() => {
    if (userRecommendations) {
      setLocalShareableRecommendations(userRecommendations);
    }
  }, [userRecommendations]);

  return (
    <div className="md:pt-10 flex flex-col gap-4 font-light pb-20">
      {!goalId && <ButtonWithIcon
        onClick={() => {
          setIsAddFromTemplatesPopupShowing(true);
        }}
        text={"Add from templates"}
        size="small"
        className="w-full justify-center flex md:justify-start"
        icon={IconOption.PLUS}
      />}
      {/* Title */}
      <label>Title</label>
      <TextInput
        value={title}
        onChange={(e) => setSession(defaultGoalId, {
          ...sessions[defaultGoalId],
          title: e.target.value,
        })}
      />
      {/* Details */}
      <label>Details</label>
      <TextArea
        value={details}
        onChange={(e) => setSession(defaultGoalId, {
          ...sessions[defaultGoalId],
          details: e.currentTarget.value,
        })}
      />
      <div className="flex gap-1 items-center">
        <label>Time Frame:</label>
        <Select
          currentOption={TimeFrame.getDropDownOptions().find(
            (option) => option.value === timeframe
          )}
          options={TimeFrame.getDropDownOptions()}
          onChange={(item) => setSession(defaultGoalId, {
            ...sessions[defaultGoalId],
            timeframe: item,
          })}
        />
      </div>
      <ButtonWithIcon
        onClick={() => {
          setIsAddRecommendationsPopupShowing(true);
        }}
        text={"Recommendations"}
        size={"small"}
        className="flex flex-row-reverse justify-end my-4"
        icon={IconOption.PLUS}
      />
      {localShareableRecommendations?.map((shareableRecommendation) => (
        <div className="flex gap-2">
          <button
            type="button"
            className="hover:scale-[1.15] transition-transform z-[2]"
            onClick={(event) => {
              event.preventDefault();
              setSession(defaultGoalId, {
                ...sessions[defaultGoalId],
                recommendationIds: recommendationIds.filter(
                  (recommendation) =>
                    recommendation !== shareableRecommendation.id
                )
              });
            }}>
            <CloseButton />
          </button>
          <RecommendationCard recommendation={shareableRecommendation} />
        </div>
      ))}
      <GoalTemplateAddPopover
        close={() => setIsAddFromTemplatesPopupShowing(false)}
        isOpen={isAddFromTemplatesPopupShowing}
        isAddingToGoal
      />
      <ShareableRecommendationAddPopover
        goalId={defaultGoalId}
        close={() => setIsAddRecommendationsFromTemplatePopupShowing(false)}
        isAddingToGoal
        isOpen={isAddRecommendationsFromTemplatePopupShowing}
      />
      <ResponsivePopup
        isOpen={isAddRecommendationsPopupShowing}
        onClose={() => setIsAddRecommendationsPopupShowing(false)}>
        <div className="p-4 font-light flex flex-col gap-3 pb-10 md:pb-0">
          <header className="w-full text-center text-lg pb-4">
            Add Recommendations
          </header>
          <ButtonWithIcon
            onClick={() => {
              setIsAddRecommendationsPopupShowing(false);
              setIsAddRecommendationsFromTemplatePopupShowing(true);
            }}
            size={"small"}
            text={"Add From Templates"}
            icon={IconOption.ARROW}
          />
          <ButtonWithIcon
            onClick={() => {
              navigate({
                path: CarePilotRoute.RECOMMENDATION_ADD,
                queryParams: {
                  goal_id: goalId ?? "",
                }
              })
            }}
            size={"small"}
            text={"Create New"}
            icon={IconOption.ARROW}
          />
        </div>
      </ResponsivePopup>
      <FooterButtons>
        {goalId ? (
          <ButtonWithIcon
            onClick={async () => {
              if (goal) {
                await deleteGoalMutation(goal);
              }
              resetSession(defaultGoalId);
              const carespaceId = authUser ? authUser?.id : '';
              navigate({
                path: CareCentralRoute.CARESPACE,
                params: {
                  carespaceId: carespaceId,
                }
              })
            }}
            text={"Delete"}
            size={"small"}
            icon={IconOption.TRASH}
          />
        ) : null}
        <ButtonWithIcon
          onClick={() => {
            resetSession(defaultGoalId);
            navigateBack()
          }}
          text={"Cancel"}
          size={"small"}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          onClick={() => {
            onSave();
            navigateBack()
          }}
          text={"Save"}
          disabled={!title || !details || !timeframe}
          size={"small"}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </div>
  );
}
