import create from "zustand";
import { persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { getCurrentYYYYMMDD } from "components/NanasDay/utils";
import { LocalStorageKeys } from "state/localStorageKeys";

// store

type State = {
  selectedScheduleDate: string;
};

type Actions = {
  setSelectedDate: (date: string) => void;
};

const initialState: State = {
  selectedScheduleDate: getCurrentYYYYMMDD(),
};

export const useNanasDayStore = create<State & Actions>()(
  persist(
    immer((set, get) => ({
      /**
       * state
       */
      ...initialState,

      /**
       * actions
       */
      setSelectedDate: (date: string) =>
        set((state) => {
          state.selectedScheduleDate = date;
        }),
    })),
    {
      name: LocalStorageKeys.nanasDay,
      getStorage: () => localStorage,
    }
  )
);

// notes on the create<T>()() 'currying' syntax
// https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#basic-usage
