import { ReactComponent as CheckedIcon } from 'assets/checkbox_icon.svg';
import { ReactComponent as UncheckedIcon } from 'assets/checkbox_icon_unchecked.svg';
import { Checkbox, CheckboxProps } from 'components/Checkbox';

export interface DefaultCheckboxProps extends Omit<CheckboxProps, 'icon' | 'uncheckedIcon'> {}

export function DefaultCheckbox({ ...props }: DefaultCheckboxProps) {
  return (
    <Checkbox 
      icon={<CheckedIcon />}
      uncheckedIcon={<UncheckedIcon />}
      {...props}
    />
  );
}
