import { Cell, Row, flexRender } from "@tanstack/react-table";
import { ServiceStatus, ServiceStatusColor } from "backend/resources/planEntry";
import { useEffect, useState } from "react";
import { ServiceEngagement, ServiceEngagementWithServiceResourceAndNetwork } from "backend/resources/services/serviceEngagement";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { ServiceFilterConfig, ServiceFilters } from "components/Services Hub/components/ServiceFilters";
import { useServicesFilteredData } from "components/Services Hub/servicesUtil";
import { InfiniteScrollingTable, TableDataRowType } from "components/Tables/InfiniteScrollingTable";

// Define your service fields and types
export enum ServiceFields {
  RowData = 'rowData',
  Carespace = 'Carespace',
  Service = 'Service',
  ServiceType = 'Service Type',
  Provider = 'Provider',
  Status = 'Status',
  ActiveOrInactiveDate = 'Active/ Inactive Date'
}
type BaseServiceType = {
  [ServiceFields.RowData]: ServiceEngagement,
  [ServiceFields.Carespace]: string | undefined,
  [ServiceFields.Service]: string | undefined,
  [ServiceFields.Provider]: string | undefined,
  [ServiceFields.Status]: ServiceStatus,
  [ServiceFields.ActiveOrInactiveDate]: string | undefined
  [ServiceFields.ServiceType]: string,
};
export type ServiceType = BaseServiceType & TableDataRowType<BaseServiceType>;



interface ServicesTableProps {
  data: ServiceEngagementWithServiceResourceAndNetwork[] | undefined;
  filterConfig?: ServiceFilterConfig<ServiceEngagementWithServiceResourceAndNetwork>;
  filtersToShow?: ServiceFilterConfig<ServiceEngagementWithServiceResourceAndNetwork>;
  hiddenColumns?: ServiceFields[];
}

export default function ServicesTable({ data: rawData, filterConfig, filtersToShow, hiddenColumns }: ServicesTableProps) {
  const navigate = useAppNavigate();
  const [data, setData] = useState<ServiceType[]>(() => []);
  const filteredData = useServicesFilteredData({
    data: rawData!!,
    config: filterConfig ?? {},
  });


  useEffect(() => {
    // Updates data
    const updatedData = filteredData?.map(item => (
      {
        [ServiceFields.RowData]: item,
        [ServiceFields.Carespace]: item.network?.name ?? undefined,
        [ServiceFields.Service]: item?.name,
        [ServiceFields.ServiceType]: item.service_resource?.categories && item.service_resource.categories !== ""
          ? item.service_resource.categories
          : "N/A",
        [ServiceFields.Provider]: item.service_resource?.name,
        [ServiceFields.Status]: item.is_active ? ServiceStatus.Active : ServiceStatus.Inactive,
        [ServiceFields.ActiveOrInactiveDate]: new Date(item.became_inactive_at ?? item.created_at).toLocaleDateString('en-US'),
      }
    ));
    const sortedData = updatedData?.sort((a, b) => new Date(b[ServiceFields.RowData].created_at).getTime() - new Date(a[ServiceFields.RowData].created_at).getTime());

    setData(sortedData ?? []);
  }, [filteredData]);


  return <div className="flex flex-col gap-5 ">
    {filtersToShow && <ServiceFilters
      filterConfig={filtersToShow}
    />}
    <InfiniteScrollingTable
      data={data}
      headersToCenter={[ServiceFields.Status]}
      columnFields={Object.values(ServiceFields)}
      handleClick={function (cell: Cell<ServiceType, unknown>, row: Row<ServiceType>): void {
        if (cell.column.id === ServiceFields.Provider) {
          navigate({
            path: SharedRoute.SERVICE_PROVIDER_PAGE,
            params: {
              id: row.original[ServiceFields.RowData].service_resource_id,
            },
          });
        } else {
          navigate({
            path: SharedRoute.SERVICE_PAGE,
            params: {
              id: row.original[ServiceFields.RowData].id
            }
          });
        }
      }}
      hiddenColumns={[...(hiddenColumns ?? []), ServiceFields.RowData]}
      CellContent={function ({ cell, row }: { cell: Cell<ServiceType, unknown>; row: Row<ServiceType>; }): JSX.Element {
        switch (cell.column.id) {
          case ServiceFields.Status:
            return (
              <p style={{
                background: ServiceStatusColor[(cell.row.original[ServiceFields.Status])]
              }}
                className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </p>
            );
          case ServiceFields.ActiveOrInactiveDate:
            return <p className={`rounded-full truncate text-center w-[120px] max-w-[120px]`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </p>
          default:
            return <p className={`rounded-full truncate text-left w-[200px] max-w-[200px]`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </p>

        }
      }} 
      emptyText="There are no services associated with the filters you selected"/>
  </div>

}


