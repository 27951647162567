import { NetworksWithAdlosAndCaregivers } from "backend/resources/network/network"
import { useUserRolesInNetwork } from "backend/resources/userRole"
import AdloComponent from "components/AdloComponent/AdloComponent"
import { Card } from "components/Card/Card"
import { CardLayoutTypes } from "components/CardLayoutSelector/CardLayoutSelector"
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing"
import { useNetworkStore } from "state/network/network"


interface CarespaceCardProps {
  activeLayout: CardLayoutTypes;
  network: NetworksWithAdlosAndCaregivers;
  shouldNavigateToConversation?: boolean;
} 

export function CarespaceCard({network, activeLayout, shouldNavigateToConversation=false}: CarespaceCardProps) {
  const navigate = useAppNavigate();
  const firstAdlo = network.user_adlo[0]; // TODO: support multi-adlo, probably with carousel (also check grid mode)
  const doctorObject = network.user_role.find((userRole) => userRole.role === "doctor");
  const doctor = doctorObject ? doctorObject.user.first_name + " " + doctorObject.user.last_name : "No Doctor Yet";

  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const { primaryCaregivers, isLoading } = useUserRolesInNetwork(
    network?.id
  );
  const firstPrimaryCaregiver = primaryCaregivers && primaryCaregivers[0];

  return (
    <div className=
      {`flex flex-col gap-2 pt-2 
          ${activeLayout === "thumbnail" ?
          "w-[160px] max-w-[160px]" :
          "w-[360px] max-w-[360px]"} 
          overflow-clip `}>
      {/* title */}
      <div className="flex flex-col w-full max-w-full overflow-clip justify-center pt-2 ">
        <p
          className={`text-xl truncate w-full ${activeLayout === "thumbnail" ? "text-center" : ""
            }`}>
          {network.name}
        </p>
      </div>
      <button
        onClick={() => {
          setActiveNetworkId(network.id);
          navigate({
            path: shouldNavigateToConversation ? CarePilotRoute.GENERAL_DISCUSSION : CareCentralRoute.CARESPACE,
            params: {
              carespaceId: network.id
            }
          })
        }}>
        {firstAdlo ? (
          <Card
            color="darkBlue"
            size={activeLayout}>
            <AdloComponent
              userAdlo={firstAdlo}
              size={activeLayout}
              isLoading={isLoading}
              withCaregiver={firstPrimaryCaregiver?.user}
              doctor={doctor}
            />
          </Card>
        ) : (
          <Card
            color="white"
            size={activeLayout}>
            <div className="text-black/50">No patients.</div>
          </Card>
        )}
      </button>
    </div>
  );
}
