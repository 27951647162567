import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon"
import CardLayoutSelector, { CardLayoutTypes } from "components/CardLayoutSelector/CardLayoutSelector"
import { CarespaceCards } from "components/HomePage/CareCentralHome/Carespaces"
import { PageContainer } from "components/PageContainer"
import { PageMainHeader } from "components/PageMainHeader"
import { useState } from "react"
import { useCarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard"
import ActionButtons from "shared/ui/action-buttons"

export function CarespacesPage() {
  const reset = useCarespaceCreationWizard((state) => state.reset)

  const [isCreatePopoverOpen, setIsCreatePopoverOpen] =
    useState<boolean>(false);
  const [activeLayout, setActiveLayout] = useState<CardLayoutTypes>(
    CardLayoutTypes.DEFAULT
  );
  return (
    <PageContainer>
      <div className="flex items-center gap-5">
        <PageMainHeader text={"Carespaces"} />
      </div>
      <div className="flex justify-center md:justify-between gap-3 items-center py-3 max-w-[68rem] w-full">

        <ActionButtons>
          <ButtonWithIcon
            onClick={() => {
              reset()
              setIsCreatePopoverOpen(true)
            }}
            text={"Add Carespace"}
            icon={IconOption.PLUS}
          />
        </ActionButtons>
        <CardLayoutSelector
          activeLayout={activeLayout}
          setActiveLayout={setActiveLayout}
        />
      </div>
      <CarespaceCards
        isCreatePopoverOpen={isCreatePopoverOpen}
        setIsCreatePopoverOpen={setIsCreatePopoverOpen}
        activeLayout={activeLayout}
        setActiveLayout={setActiveLayout}
      />
    </PageContainer>

  )
}
