import { DatePicker } from "@mui/x-date-pickers";
import { AssessmentType, ClinicianAttestation, DementiaStageAssessmentTool, HasPrimaryCareGiver, PhoneType, ResidenceType } from "components/CarespacePage/PAFSubmission/pafEnums";
import { getCurrentValueForSelect, getSelectOptionsFromEnum, PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { DefaultCheckbox } from "components/Checkbox";
import { Select } from "components/Select/Select";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { CustomFormField, Form, FormRoot } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { z } from "zod";
import { InitialAssessmentInputs } from "./InitialAssessmentInputs";
import { PrimaryCareGiverInputs } from "./PrimaryCareGiverInputs";
import { PrimaryCarePhysicianInputs } from "./PrimaryCarePhysicianInputs";


interface PAFSubmissionFormProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const PAFSubmissionForm: React.FC<PAFSubmissionFormProps<z.infer<typeof PAFSchema>>> = ({ isReadOnly, form }) => {
  const formValues = form.watch();
  const dementiaStageScoreLabel = formValues.staging_tool ? formValues.staging_tool : DementiaStageAssessmentTool.FAST;
  const showPCGInputs = formValues.has_pcg == HasPrimaryCareGiver.YES_ONE || formValues.has_pcg == HasPrimaryCareGiver.YES_MULTIPLE;
  const showInitialAssessmentInputs = formValues.assessment_type == AssessmentType.INITIAL_ASSESSMENT;

  return (
    <Form {...form} >
      <FormRoot id={"paf-submission-form"} >
        {/* Assessment Date */}
        <CustomFormField
          form={form}
          disabled={isReadOnly}
          labelName="Assessment Date"
          fieldName="assessment_date"
          children={(field) => (
            <DatePicker {...field} />
          )}
        />
        {/* Assessment Type */}
        <CustomFormField
          form={form}
          disabled={isReadOnly}
          labelName="Assessment Type"
          fieldName="assessment_type"
          children={(field) => (
            <Select
              options={getSelectOptionsFromEnum(AssessmentType)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
        {showInitialAssessmentInputs && (
          <InitialAssessmentInputs 
            form={form}
            isReadOnly={isReadOnly}
          />
        )}
        {/* First Name */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="First Name"
          fieldName="first_name"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Middle Initial */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Middle Initial"
          fieldName="middle_initial"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Last Name */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Last Name"
          fieldName="last_name"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Address */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Address"
          fieldName="address_line"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* City */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="City"
          fieldName="address_city"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* State */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="State"
          fieldName="address_state"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Zip Code */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Zip Code"
          fieldName="address_postalCode"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Email */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Email"
          fieldName="email"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Phone */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Phone"
          fieldName="phone"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Phone Type */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Phone Type"
          fieldName="phone_type"
          children={(field) => (
            <Select 
            options={getSelectOptionsFromEnum(PhoneType)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
          )}
        />
        {/* Residence Type */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Residence Type"
          fieldName="residence_type"
          children={(field) => (
            <Select 
              options={getSelectOptionsFromEnum(ResidenceType)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
        {/* Not Nursing Home */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Not Nursing Home"
          fieldName="not_nursing_home"
          children={(field) => (
            <DefaultCheckbox {...field} />
          )}
        />
        {/* Date of Birth */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Date of Birth"
          fieldName="date_of_birth"
          children={(field) => (
            <DatePicker {...field} />
          )}
        />
        {/* Medicare Beneficiary Identifier */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Medicare Beneficiary Identifier"
          fieldName="mbi"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Medicaid Id */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Medicaid Id"
          fieldName="medicaid_id"
          children={(field) => (
            <Input {...field} />
          )}
        />
        {/* Staging Tool */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Staging Tool"
          fieldName="staging_tool"
          children={(field) => (
            <Select 
              options={getSelectOptionsFromEnum(DementiaStageAssessmentTool)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
        {/* Dementia Stage Score */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName={`${dementiaStageScoreLabel} Score`}
          fieldName="dementia_stage_score"
          children={(field) => (
            <Input type={"number"} {...field} />
          )}
        />
        {/* Patient has PCP */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Patient has PCP"
          fieldName="patient_pcp"
          children={(field) => (
            <DefaultCheckbox {...field} />
          )}
        />
        {formValues.patient_pcp && (
          <PrimaryCarePhysicianInputs form={form} isReadOnly={isReadOnly} />
        )}
        {/* Has Patient Caregiver */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Patient has Caregiver"
          fieldName="has_pcg"
          children={(field) => (
            <Select 
              options={getSelectOptionsFromEnum(HasPrimaryCareGiver)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
        {showPCGInputs && (
          <PrimaryCareGiverInputs form={form} isReadOnly={isReadOnly} />
        )}
        {/* Clinician Attestation */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Clinician Attestation"
          fieldName="clinician_attestation"
          children={(field) => (
            <Select 
              options={getSelectOptionsFromEnum(ClinicianAttestation)}
              currentOption={getCurrentValueForSelect(field)}
              {...field}
            />
          )}
        />
        {/* Clinician First Name */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Clinician First Name"
          fieldName="clinician_first_name"
          children={(field) => (
            <Input type={"text"} {...field} />
          )}
        />
        {/* Clinician Middle Name */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Clinician Middle Name"
          fieldName="clinician_middle_name"
          children={(field) => (
            <Input type={"text"} {...field} />
          )}
        />
        {/* Clinician Last Name */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Clinician Last Name"
          fieldName="clinician_last_name"
          children={(field) => (
            <Input type={"text"} {...field} />
          )}
        />
        {/* Clinician National Provider Identification Number */}
        <CustomFormField 
          form={form}
          disabled={isReadOnly}
          labelName="Clinician National Provider Identification Number"
          fieldName="npi"
          children={(field) => (
            <Input type={"text"} {...field} />
          )}
        />
      </FormRoot>
    </Form>
  );
}
