import { useScheduleDatesQuery } from "backend/resources/userPrescriptionScheduleDate/userPrescriptionScheduleDate";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { AddPrescriptionView } from "components/NanasDay/AddPrescriptionView";
import { EditPrescriptionView } from "components/NanasDay/EditPrescriptionView";
import { EditScheduleView } from "components/NanasDay/EditScheduleView";
import { IntakeView } from "components/NanasDay/IntakeView";
import { MobileNotesView } from "components/NanasDay/MobileNotesView";
import { PrescriptionDetailView } from "components/NanasDay/PrescriptionDetailView";
import { ScheduleView } from "components/NanasDay/ScheduleView";
import { getCurrentYYYYMMDD } from "components/NanasDay/utils";
import { PageContainer } from "components/PageContainer";
import { Select } from "components/Select";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useNanasDayStore } from "state/nanasDay";
import { NotesView } from "./NotesView";

type Props = {
  should_display_create_form?: boolean;
  should_display_detail_page?: boolean;
  should_display_edit_page?: boolean;
  should_display_edit_schedule_page?: boolean;
  should_display_notes_page?: boolean;
  should_display_intake_view?: boolean;
  should_display_schedule_view?: boolean;
};

export function NanasDay(props: Props) {
  const {
    should_display_create_form,
    should_display_detail_page,
    should_display_edit_page,
    should_display_edit_schedule_page,
    should_display_notes_page,
    should_display_intake_view,
    should_display_schedule_view,
  } = props;

  const isRootView = !(
    should_display_edit_schedule_page ||
    should_display_notes_page ||
    should_display_create_form ||
    should_display_edit_page ||
    should_display_detail_page
  );

  const renderPageContent = () => {
    if (should_display_edit_schedule_page) return <EditScheduleView />;
    if (should_display_notes_page) return <MobileNotesView />;
    if (should_display_create_form) return <AddPrescriptionView />;
    if (should_display_edit_page) return <EditPrescriptionView />;
    if (should_display_detail_page) return <PrescriptionDetailView />;
    return null;
  };

  return (
    <>
      {isRootView ? (
        <NanasDayRootView
          should_display_intake_view={should_display_intake_view}
          should_display_schedule_view={should_display_schedule_view}
        />
      ) : (
        <PageContainer>{renderPageContent()}</PageContainer>
      )}
    </>
  );
}

function NanasDayRootView({ should_display_intake_view, should_display_schedule_view }: Props) {
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const selectedScheduleDate = useNanasDayStore((state) => state.selectedScheduleDate);
  const setSelectedDate = useNanasDayStore((state) => state.setSelectedDate);
  const { isMobile } = useWindowSize();
  const { scheduleDates, isLoadingScheduleDates } = useScheduleDatesQuery();
  const navigate = useAppNavigate();

  const scheduleDateSelectOptions = (scheduleDates || []).reduce<
    { value: string; label: string }[]
  >((acc, val) => {
    if (val.unique_date) {
      acc.push({
        value: val.unique_date,
        label: val.unique_date,
      });
    }
    return acc;
  }, []);


  useEffect(() => {
    if (!scheduleDateSelectOptions.some((option) => option.value === selectedScheduleDate)) {
      setSelectedDate(getCurrentYYYYMMDD());
    }
  }, [scheduleDateSelectOptions, selectedScheduleDate, setSelectedDate]);

  if (should_display_intake_view) {
    return <IntakeView />;
  }

  const renderSelect = (isMobile: boolean) => {
    const className = (
      isMobile ? "flex-grow flex flex-col items-center justify-center gap-2" : "flex items-center gap-4");
    return (
      <div className={className}>
        <span>Date</span>
        <Select
          onChange={(item) => setSelectedDate(item)}
          disabled={scheduleDateSelectOptions.length === 0}
          options={scheduleDateSelectOptions}
          currentOption={
            scheduleDateSelectOptions?.find(
              (scheduleDate) =>
                scheduleDate.value === selectedScheduleDate
            ) ||
            scheduleDateSelectOptions?.find(
              (scheduleDate) =>
                scheduleDate.value ===
                scheduleDateSelectOptions?.[0]?.value
            )
          }
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col h-full gap-6">
      {isMobile ? (
        <div className="flex w-full justify-between p-1">
          {isLoadingScheduleDates ? <LoadingSpinner className="w-6 h-6" /> : renderSelect(true)}
        </div>
      ) : (
        <>
          <div className="flex gap-4">
            {isLoadingScheduleDates ? <LoadingSpinner className="w-6 h-6" /> : renderSelect(false)}
            <ButtonWithIcon
              onClick={() => setIsNotesModalOpen(true)}
              className="text-md font-light"
              icon={IconOption.NOTES}
              text="Notes"
            />
            <ButtonWithIcon
              onClick={() => navigate({ path: CarePilotRoute.MEDICATION_INTAKE })}
              className="text-md font-light"
              icon={IconOption.INTAKE}
              text="Intake"
            />
          </div>

        </>
      )}
      <ScheduleView />
      <ResponsiveModal
        isOpen={isNotesModalOpen}
        onClose={() => setIsNotesModalOpen(false)}
        title="Notes"
      >
        <NotesView textAreaHeight="300px" />
      </ResponsiveModal>
    </div>
  );
}