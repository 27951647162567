import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  Path,
  UseFormReturn,
  useFormContext,
} from "react-hook-form";

import { Label } from "shared/ui/label";
import { cn } from "shared/utils/cn";

const Form = FormProvider;

type FormFieldContextValue<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  name: TName;
};

const FormFieldContext = React.createContext<FormFieldContextValue>(
  {} as FormFieldContextValue
);

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

interface CustomFormFieldProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  fieldName: Path<T>;
  children: (field: any) => React.ReactNode;
  labelName: string;
  disabled?: boolean;
}

export function CustomFormField<T extends FieldValues>({ form, fieldName, children, labelName, disabled = false }: CustomFormFieldProps<T>) {
  const [isRequired, setIsRequired] = React.useState(false)
  form.control._executeSchema([fieldName]).then((result) => {
    if (result.errors[fieldName]?.message === "Required" && !isRequired) {
      setIsRequired(true)
    }
  })
  return (
    <FormField
      control={form.control}
      name={fieldName}
      disabled={disabled}
      render={({ field }) => (
        <FormItem>
          <FormLabel className="flex gap-2 text-base">
            {labelName}{isRequired && <span className="text-red-500">*</span>}
          </FormLabel>
          <FormControl>
            {children(field)}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error("useFormField should be used within <FormField>");
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

type FormItemContextValue = {
  id: string;
};

const FormItemContext = React.createContext<FormItemContextValue>(
  {} as FormItemContextValue
);

const FormItem = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  const id = React.useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div
        ref={ref}
        className={cn("space-y-2", className)}
        {...props}
      />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && "text-red-900", className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = "FormLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn(
        "text-sm text-neutral-500 dark:text-neutral-400",
        className
      )}
      {...props}
    />
  );
});
FormDescription.displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? error.message : children;
  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn(
        "text-sm font-medium text-red-900",
        className
      )}
      {...props}>
      {body}
    </p>
  );
});
FormMessage.displayName = "FormMessage";

const FormRoot = ({
  id,
  onSubmit,
  children,
}: {
  id: string;
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined;
  children: React.ReactNode;
}) => {
  return (
    // p-1 on the outer form ensures focus ring shows regardless of parent overflow styling
    (<form
      id={id}
      onSubmit={onSubmit}
      className="flex flex-col gap-6">
      {children}
    </form>)
  );
};

const FormSection = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-3">{children}</div>;
};

const FormSectionHeader = ({ title }: { title: React.ReactNode }) => {
  return <span className="text-lg">{title}</span>;
};

export {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRoot,
  FormSection,
  FormSectionHeader,
  useFormField
};

