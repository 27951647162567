import {
  useDailyScheduleNoteQuery,
  useUpsertDailyScheduleNote,
} from "backend/resources/userNote";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FormContainer } from "components/PageContainer/FormContainer";
import { TextArea } from "components/TextArea";
import { useEffect, useState } from "react";
import { useNanasDayStore } from "state/nanasDay";
import { useUserStore } from "state/user";

interface NotesViewProps {
  textAreaHeight: string;
} 

export function NotesView({ textAreaHeight }: NotesViewProps) {
  const authUser = useUserStore((state) => state.user);

  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );

  const {
    isLoadingDailyScheduleNote: isLoading,
    dailyScheduleNote,
    refetchDailyScheduleNote,
  } = useDailyScheduleNoteQuery(authUser?.id, selectedScheduleDate);

  const upsertDailyScheduleNote = useUpsertDailyScheduleNote().mutateAsync;

  const [currentNote, setCurrentNote] = useState(dailyScheduleNote?.note);
  const [isModified, setIsModified] = useState(false);

  // Update currentNote and isModified when note changes
  useEffect(() => {
    setCurrentNote(dailyScheduleNote?.note);
    setIsModified(false);
  }, [dailyScheduleNote]);

  // show loading spinner for note on day change
  useEffect(() => {
    refetchDailyScheduleNote();
  }, [selectedScheduleDate]);

  function handleNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCurrentNote(e.target.value);
    setIsModified(e.target.value !== dailyScheduleNote?.note);
  }

  async function saveNote() {
    if (isModified && authUser) {
      await upsertDailyScheduleNote({
        user_id: authUser.id,
        user_daily_schedule_date: selectedScheduleDate,
        note: currentNote,
      });
      setIsModified(false);
    }
  }

  return (
    <div className="w-full h-full pb-48 md:pb-0">
      {isLoading ? (
        <LoadingSpinner className="w-20 h-20" />
      ) : (
        <>
          <FormContainer>
            <TextArea
              className={`w-full h-full h-[${textAreaHeight}]`}
              data-hj-allow
              value={currentNote || ""}
              onChange={handleNoteChange} // Add this line
            />
            <div className="mt-4 flex w-full justify-end gap-3">
              <ButtonWithIcon
                disabled={!isModified}
                text="Save"
                onClick={(e: any) => {
                  e.stopPropagation();
                  saveNote();
                }}
                icon={IconOption.CHECKMARK}
              />
            </div>
          </FormContainer>
        </>
      )}
    </div>
  );
}
