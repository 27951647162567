import * as React from "react"

import { cn } from "shared/utils/cn"

const SHARED_INPUT_CLASSNAMES = "flex h-10 w-full rounded-md border border-neutral-200 bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-neutral-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"

/**
 * text input
 */
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> { }

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          SHARED_INPUT_CLASSNAMES,
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

/**
 * numeric input
 */

interface NumericInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
}

const NumericInput = React.forwardRef<HTMLInputElement, NumericInputProps>(
  ({ className, type, onChange, min = 0, max = 100, ...props }, ref) => {
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let valueStr = event.target.value;
      // Remove leading and consecutive zeros
      valueStr = valueStr.replace(/^0+|(?<=0)0+/g, '');
      let value = parseInt(valueStr, 10);
      if (isNaN(value)) {
        value = min;
      } else if (value < min) {
        value = min;
      } else if (value > max) {
        value = max;
      }
      onChange?.(value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      // Allow numeric characters, backspace, delete, and tab
      if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete', 'Tab'].includes(event.key)) {
        event.preventDefault();
      }
    };

    return (
      <input
        type="text"
        className={cn(
          SHARED_INPUT_CLASSNAMES,
          className
        )}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        ref={ref}
        {...props}
      />
    );
  }
);


NumericInput.displayName = 'NumericInput';

export { Input, NumericInput }

