import { useOrgs } from "backend/resources/orgRole";
import { useCareBinderName } from "backend/resources/userAdlo";
import {
  useDailyScheduleNoteQuery,
  useUpsertDailyScheduleNote,
} from "backend/resources/userNote";
import { useScheduleDatesQuery } from "backend/resources/userPrescriptionScheduleDate/userPrescriptionScheduleDate";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { LoadingSpinner } from "components/LoadingSpinner";
import ModalView from "components/ModalView/ModalView";
import { getCurrentYYYYMMDD } from "components/NanasDay/utils";
import { PageMainHeader } from "components/PageMainHeader";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import { useNanasDayStore } from "state/nanasDay";
import { useUserStore } from "state/user";

export function MobileNotesView() {
  /**
   * START NON DRY CODE FROM NANAS DAY ROOT
   *
   * Maybe we can stuff some of this in a shared hook? At least it's isolated.
   */
  const selectedScheduleDate = useNanasDayStore(
    (state) => state.selectedScheduleDate
  );
  const setSelectedDate = useNanasDayStore((state) => state.setSelectedDate);
  const { hasCareCentralAccess } = useOrgs();
  const authUser = useUserStore((state) => state.user);

  const { scheduleDates, isLoadingScheduleDates } = useScheduleDatesQuery();

  const {
    isLoadingDailyScheduleNote: isLoading,
    dailyScheduleNote,
    refetchDailyScheduleNote,
  } = useDailyScheduleNoteQuery(authUser?.id, selectedScheduleDate);

  const upsertDailyScheduleNote = useUpsertDailyScheduleNote().mutateAsync;

  const scheduleDateSelectOptions = (scheduleDates || []).reduce<
    { value: string; label: string }[]
  >((acc, val) => {
    if (val.unique_date) {
      acc.push({
        value: val.unique_date,
        label: val.unique_date,
      });
    }
    return acc;
  }, []);

  useEffect(() => {
    const selectedScheduleDateOption = scheduleDateSelectOptions.find(
      (scheduleDate) => scheduleDate.value === selectedScheduleDate
    );

    if (!selectedScheduleDateOption) {
      setSelectedDate(getCurrentYYYYMMDD());
    }
  }, []);

  /**
   * END NON-DRY CODE FROM NANAS DAY ROOT
   */

  const [isEditing, setIsEditing] = useState(false);
  const [currentNote, setCurrentNote] = useState(dailyScheduleNote?.note);
  const [isModified, setIsModified] = useState(false);

  const navigate = useAppNavigate();

  const { careBinderName, isLoadingCareBinderName } = useCareBinderName();

  // Update currentNote and isModified when note changes
  useEffect(() => {
    setCurrentNote(dailyScheduleNote?.note);
    setIsModified(false);
  }, [dailyScheduleNote]);

  // show loading spinner for note on day change
  useEffect(() => {
    refetchDailyScheduleNote();
  }, [selectedScheduleDate]);

  function handleNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCurrentNote(e.target.value);
    setIsModified(e.target.value !== dailyScheduleNote?.note);
  }

  function saveNote() {
    if (isModified && authUser) {
      upsertDailyScheduleNote({
        user_id: authUser.id,
        user_daily_schedule_date: selectedScheduleDate,
        note: currentNote,
      });
      setIsModified(false);
    }
  }

  if (isEditing) {
    return (
      <ModalView
        isOpen={true}
        closeText="Back"
        onClose={() => {
          setIsEditing(false);
        }}>
        <div className="flex flex-col h-[calc(100%-80px)] w-full gap-4">
          {/* header */}
          <div className="flex flex-col items-center justify-center">
            <PageMainHeader text="Edit Notes" />
            {isLoadingScheduleDates ? (
              <LoadingSpinner className="w-6 h-6" />
            ) : (
              <Select
                borderClass="border-none"
                onChange={(item) => {
                  setSelectedDate(item);
                }}
                disabled={scheduleDateSelectOptions?.length === 0}
                options={scheduleDateSelectOptions}
                currentOption={
                  scheduleDateSelectOptions.find(
                    (scheduleDate) =>
                      scheduleDate.value === selectedScheduleDate
                  ) ||
                  scheduleDateSelectOptions.find(
                    (scheduleDate) =>
                      scheduleDate.value ===
                      scheduleDateSelectOptions?.[0]?.value
                  )
                }
              />
            )}
          </div>
          {/* content */}
          <div className="flex-grow w-full px-6">
            {isLoading ? (
              <LoadingSpinner className="w-20 h-20" />
            ) : (
              <>
                <TextArea
                  className="w-full h-full"
                  data-hj-allow
                  value={currentNote || ""}
                  onChange={handleNoteChange} // Add this line
                  placeholder="Type your note..."
                />
              </>
            )}
          </div>
        </div>
        {/* buttons row */}
        <FooterButtons>
          <ButtonWithIcon
            onClick={() => {
              setIsEditing(false);
            }}
            icon={IconOption.CANCEL}
            text="Cancel"
          />
          <ButtonWithIcon
            disabled={!isModified}
            text="Save"
            onClick={(e: any) => {
              e.stopPropagation();
              saveNote();
            }}
            icon={IconOption.CHECKMARK}
          />
        </FooterButtons>
      </ModalView>
    );
  } else {
    return (
      <div className="flex flex-col h-[calc(100%-120px)] gap-4">
        {/* back button */}
        <div>
          {isLoadingCareBinderName ? (
            <LoadingSpinner className="h-6 w-6" />
          ) : (
            <ButtonWithIcon
              onClick={() => {
                const carespaceId = authUser ? authUser?.id : '';
                if (hasCareCentralAccess) {
                  navigate({
                    path: CareCentralRoute.CARESPACE,
                    params: { carespaceId: carespaceId }
                  });
                } else {
                  navigate({
                    path: CarePilotRoute.ASSESSMENTS
                  });
                }
              }}
              disabled={false}
              text={careBinderName}
              icon={IconOption.BACK_ARROW}
            />
          )}
        </div>
        {/* header */}
        <div className="flex flex-col items-center justify-center">
          <PageMainHeader text="Notes" />
          {isLoadingScheduleDates ? (
            <LoadingSpinner className="w-6 h-6" />
          ) : (
            <Select
              borderClass="border-none"
              onChange={(item) => {
                setSelectedDate(item);
              }}
              disabled={scheduleDateSelectOptions?.length === 0}
              options={scheduleDateSelectOptions}
              currentOption={
                scheduleDateSelectOptions.find(
                  (scheduleDate) => scheduleDate.value === selectedScheduleDate
                ) ||
                scheduleDateSelectOptions.find(
                  (scheduleDate) =>
                    scheduleDate.value === scheduleDateSelectOptions?.[0]?.value
                )
              }
            />
          )}
        </div>
        {/* content */}
        <div className="flex flex-col flex-grow w-full">
          {isLoading ? (
            <LoadingSpinner className="w-20 h-20" />
          ) : (
            <>
              <TextArea
                className="w-full h-full"
                data-hj-allow
                value={currentNote || ""}
                disabled
              />
            </>
          )}
        </div>
        <BottomActionBar
          centerSlot={
            <ButtonWithIcon
              className="bg-white rounded-full"
              onClick={() => {
                setIsEditing(true);
              }}
              icon={IconOption.EDIT}
              text=""
            />
          }
        />
      </div>
    );
  }
}
