/**
 * @fileoverview Sets up the Zustand library folders store.
 * This store holds information about library folders state.
 */
import { useEffect } from "react";
import create from "zustand";
import { persist } from "zustand/middleware";
import { useNetworkIds } from "backend/resources/userRole";
import { resetAssessmentStore } from "state/assessment";
import { LocalStorageKeys } from "state/localStorageKeys";

/**
 * Hook to access the library folders store
 */

type State = {
  activeNetworkId?: string | undefined;
};

type Actions = {
  setActiveNetworkId: (activeNetworkId?: string) => void;
  reset: () => void;
};

const initialState: State = {
  activeNetworkId: undefined,
};

export const useNetworkStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,

      setActiveNetworkId: (activeNetworkId?: string) => {
        set({ activeNetworkId });
        resetAssessmentStore();
      },

      reset: () => set({ ...initialState }),
    }),
    {
      name: LocalStorageKeys.network, // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);

export function useActiveNetworkId() {
  const { networkIds } = useNetworkIds();

  const { activeNetworkId, setActiveNetworkId } = useNetworkStore();

  useEffect(() => {
    if (!activeNetworkId && networkIds && networkIds?.length > 0) {
      setActiveNetworkId(networkIds[0]);
    }
  }, [activeNetworkId, networkIds, setActiveNetworkId]);

  return activeNetworkId || networkIds?.[0];
}

export function getActiveNetworkIdDirectly() {
  return useNetworkStore.getState().activeNetworkId;
}
