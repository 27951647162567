import { useLaunchDarklyInfoQuery } from "backend/functions";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ReactNode, useEffect } from "react";
import { useUserStore } from "state/user";

import create from "zustand";
import { persist } from "zustand/middleware";


type State = {
  launchDarklyIds: Record<string, string>;
  setLaunchDarklyId: (userId: string, encryptedUserId: string) => void;
};

export const useLaunchDarklyIdStore = create<State>()(
  persist(
    (set) => ({
      launchDarklyIds: {},
      setLaunchDarklyId: (userId, encryptedUserId) => set((state) => ({
        launchDarklyIds: {
          ...state.launchDarklyIds,
          [userId]: encryptedUserId,
        },
      })),
    }),
    {
      name: 'launchDarklyIds',
      getStorage: () => localStorage,
    }
  )
);



export default function LaunchDarklyProvider({ children }: { children: ReactNode }) {
  const authUser = useUserStore((state) => state.user);
  const { launchDarklyIds, setLaunchDarklyId } = useLaunchDarklyIdStore();

  const { data } = useLaunchDarklyInfoQuery();

  // Use effect to update the store if it's empty
  useEffect(() => {
    if (authUser && !launchDarklyIds[authUser.id] && data?.encrypted_user_id) {
      setLaunchDarklyId(authUser.id, data?.encrypted_user_id);
    }
  }, [authUser, data, launchDarklyIds, setLaunchDarklyId]);

  const attributes = data?.attributes;
  const LDProviderWithUser = withLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_KEY,
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
    user: {
      key: authUser ? launchDarklyIds[authUser.id] : undefined,
      custom: attributes,
    },
  })(() => <>{children}</>);
  // If there is no authUser, don't initialize LD at all
  if (!authUser) {
    return <>{children}</>;
  }

  return <LDProviderWithUser />;
}