import { outlinedInputClasses } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useConversationDocumentsByConversationId } from "backend/resources/conversation_document/conversation_document";
import { useNetworkName } from "backend/resources/network/network";
import {
  PlaceTypeSchema,
  TaskStatus,
  TaskStatusColor,
  TaskStatusLabel,
  useAddConversationToPlanEntry,
  usePlanEntryWithGuideTask,
  useUpdatePlanEntry
} from "backend/resources/planEntry";
import BackButton from "components/BackButton/BackButton";
import GUIDETasksCompletedInCall from "components/GUIDETaskPage/components/GUIDETasksCompletedInCallt";
import { TaskManagementSection } from "components/GUIDETaskPage/components/TaskManagementSection";
import { LoadingSpinner } from "components/LoadingSpinner";
import AddAttendeesButtonPopup from "components/MyPlanPage/components/AddAttendeesButtonPopup";
import VideoCallButton from "components/MyPlanPage/components/VideoCallButton";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { PageContainer } from "components/PageContainer";
import { HeaderNamePill } from "components/PageMainHeader/PageMainHeaderWithNetwork";
import { GoogleAutocomplete } from "components/PlacesAutocomplete";
import { Select } from "components/Select";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import { GuideCategoryColorStyle } from "components/TaskNavigatorPage/taskTableUtils";
import { TextArea } from "components/TextArea";
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';




export const RECURRING_INTERVAL_OPTIONS = [
  { value: "None", label: "N/A" },
  { value: '1 day', label: 'Daily' },
  { value: '7 days', label: 'Weekly' },
  { value: '1 mon', label: 'Monthly' },
  { value: '3 mons', label: 'Quarterly' },
  { value: '14 days', label: 'Bi-Weekly' },
  { value: '1 year', label: 'Yearly' },
];

export const STATUS_OPTIONS = Object.entries(TaskStatusLabel).map(
  ([key, label]) => {
    return {
      value: key,
      label,
      background: TaskStatusColor[key as TaskStatus],
    };
  }
)

const GUIDE_IDENTIFIER_FOR_RECURRING_CALLS = "4.2"

export function GUIDETaskPage() {
  const { id } = useParams<{ id: string }>();
  // state
  const [notes, setNotes] = useState<string | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);


  // queries
  const { data: entryData, isLoading: isLoadingEntryData } = usePlanEntryWithGuideTask(id)
  const { networkName, isLoading: isLoadingNetworkName } = useNetworkName(entryData?.network_id ?? undefined)
  const { data: documents, isLoading: isDocumentsLoading } = useConversationDocumentsByConversationId(
    entryData?.conversation_id ?? undefined
  )
  const isGuideCall = entryData?.guide_task?.GUIDE_identifier === GUIDE_IDENTIFIER_FOR_RECURRING_CALLS
  // Mutations
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync
  const addConversationToPlanEntry = useAddConversationToPlanEntry().mutateAsync;

  // Effects
  useEffect(() => {
    if (entryData && initialLoad) {
      setNotes(entryData.notes ?? "")
      setInitialLoad(false)
      if (!entryData.conversation_id) {
        // Documents in tasks are currently tied up to conversations.
        // We don't currently expose the conversation for tasks, though later we can.
        addConversationToPlanEntry({
          taskId: entryData.id,
          isExternal: false,
          external_participant_id: entryData.user_id,
          networkId: entryData.network_id!!,
        })
      }
    }
  }, [entryData, initialLoad]);


  if (isLoadingNetworkName || isLoadingEntryData) {
    return <LoadingSpinner />
  }
  // ----------------- // ----------------- // ----------------- // ----------------- v // -----------------
  return (
    <PageContainer>
      <div className="flex flex-col gap-4 mx-5 max-w-3xl">
        <BackButton />

        {/* plan entry view */}
        <div className="flex gap-4 w-full">
          <HeaderNamePill text={networkName} color="#176590" />
          <HeaderNamePill text={entryData?.guide_task?.guide_category?.title} color={GuideCategoryColorStyle[entryData?.guide_task?.guide_category?.title ?? ""]} />
        </div>
        {/* header */}
        <div className='  w-full'>
          <p className={` text-3xl font-light w-full text-center md:text-left`}>{entryData?.name}</p>
        </div>
        {/* description group */}
        <label htmlFor="new_plan_entry_description text-base">Description <p className='text-sm mt-1'>{entryData?.guide_task?.description}</p></label>
        <div className="grid grid-cols-3 gap-4 w-[800px]">
          <div>
            <p className='text-sm mb-1'>Status</p>
            {entryData && <Select
              onChange={(value) => {
                if (value) {
                  updatePlanEntry({ ...entryData, status: value as TaskStatus })
                }
              }}
              fontSize='text-xs'
              classNames="text-xs w-[250px] whitespace-nowrap text-center text-base border-gray-300 h-[30px]"
              options={STATUS_OPTIONS}
              currentOption={{
                value: entryData.status,
                label:
                  TaskStatusLabel[entryData.status as TaskStatus],
                background:
                  TaskStatusColor[entryData.status as TaskStatus],
              }}
            />}
          </div>
          <div>
            <p className='text-sm mb-1'>Assigned To</p>
            <Who
              className='w-[250px] h-[30px] text-xs'
              userType={UserType.ORG_USERS}
              isEditing
              fontSize='text-xs'
              networkId={entryData?.network_id}
              entry={entryData}
              selectedPlanEntryOwnerId={entryData?.user_id}
              setPlanEntryOwner={(planEntryOwner: string) => updatePlanEntry({ ...entryData, user_id: planEntryOwner })}
            />
          </div>
          <p></p>
          {/* date and recurring */}
          <div>
            <p className='text-sm mb-1'>Recurring Interval</p>
            <Select
              disabled={isGuideCall}
              onChange={(value) => { if (value) updatePlanEntry({ ...entryData, recurring_interval: value }) }}
              options={RECURRING_INTERVAL_OPTIONS}
              currentOption={entryData?.recurring_interval ? RECURRING_INTERVAL_OPTIONS.find(option => option.value === entryData.recurring_interval) : { value: "None", label: "N/A" }}
              classNames='w-[250px] border-gray-300 h-[30px]'
              fontSize='text-xs'
            />
          </div>
          <div className='flex justify-between max-w-[380px] w-[380px]'>
            <div>
              <p className='text-sm mb-1'>Due</p>
              <DatePicker
                className='h-[30px] w-full '
                onChange={(value) => { if (value) updatePlanEntry({ ...entryData, scheduled_date_time: value.toISOString() }) }}
                value={entryData?.scheduled_date_time ? dayjs(entryData?.scheduled_date_time) : dayjs()}
                slotProps={{
                  field: {
                    onKeyDown: (e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                      }
                    },
                  },
                  textField: {
                    sx: {
                      fieldset: {
                        borderRadius: 99, borderColor: '#d1d5db',
                      },
                      [`.${outlinedInputClasses.root}`]: {
                        height: 30,
                        width: 260,
                        fontSize: 12,
                        fontWeight: 400,
                        fontFamily: 'Poppins',
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          {isGuideCall && <div className='flex flex-col'>
            <label className='text-sm mb-1'>Time</label>
            <TimePicker
              ampm={true}
              className='border-zinc-200'
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
                textField: {
                  sx: {
                    fieldset: {
                      borderRadius: 2, borderColor: '#E4E4E7',
                    },
                    [`.${outlinedInputClasses.root}`]: {
                      height: 30,
                      width: 165,
                      fontSize: 12,
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                    },
                  },
                },
              }}
              value={entryData?.scheduled_date_time ? dayjs(entryData?.scheduled_date_time) : dayjs()}
              onChange={(newValue: dayjs.Dayjs | null) =>
                updatePlanEntry({ ...entryData, scheduled_date_time: newValue?.toISOString() })
              }
            />
          </div>}
          {isGuideCall && <>
            <div className="flex flex-col">
              <label className='text-sm'>Location</label>
              <GoogleAutocomplete
                height="small"
                paddingTop="small"
                font="small"
                initialValue={entryData?.location ? PlaceTypeSchema.parse(entryData?.location) : null}
                onValueChange={(value, _) => {
                  updatePlanEntry({ ...entryData, location: JSON.parse(JSON.stringify(value)) });
                }}
              />
            </div>
            {/* Add Attendee(s) */}
            <AddAttendeesButtonPopup
              editOptions={{ setAttendees: (attendees: string[]) => updatePlanEntry({ ...entryData, plan_entry_attendee: attendees.map(attendee => ({ user_id: attendee })) }) }}
              initialAttendeeIds={entryData?.plan_entry_attendee?.map(attendee => attendee.user_id) ?? []}
            />
            <VideoCallButton googleMeetingCode={entryData?.google_meeting_code} setGoogleMeetingCode={(code: string) => updatePlanEntry({ ...entryData, google_meeting_code: code })} />

          </>
          }
        </div>

        <div>
          <p className='text-sm mb-1'>Notes</p>
          <TextArea
            onChange={async (value) => {
              if (value) {
                setNotes(value.target.value)
                await updatePlanEntry({ ...entryData, notes: value.target.value })
              }
            }}
            value={notes ?? ""}
            className="w-full text-sm mb-4"
          />
        </div>

        {/* Documents section */}
        {entryData && <DocumentsUploadSection
          documents={documents ?? []}
          networkId={entryData?.network_id!!}
          conversationId={entryData?.conversation_id}
          titleSize="md"
          isExternal={false} />
        }

        {isGuideCall ?
          <GUIDETasksCompletedInCall plan_entry_id={id} />
          : <TaskManagementSection id={id} />}
      </div>
    </PageContainer>
  );
}
